import * as React from "react";

import { sbEditable } from "@storyblok/storyblok-editable";
import useStoryblok from "../../lib/storyblok";

import Layout from "../../components/Layout/Layout";
import Seo from "../../components/seo";
import DynamicComponent from "../../components/dynamicComponent/DynamicComponent";
import RichText from "../../components/RichText/RichText";
import HeroHeader from "../../components/header/HeroHeader";
import Section from "../../components/section/Section";
import { Link } from "gatsby";
import ArticleCardGroup from "../../components/articleCard/ArticleCardGroup";

import { article as articleStyle, postContent as contentContainerStyle, backButton as backButtonStyle, suggestedArticles } from "./case-study.module.scss";

const CaseStudyPost = ({ pageContext, location }) => {
  let story = pageContext;
  story = useStoryblok(story, location);

  let heroHeaderblok = {
    "_uid": story.content._uid,
    "bgImg": story.content.coverImage,
    "title": "CASE STUDY",
    "variant": "banner-cover",
    "component": "HeroHeader",
    "description": story.content.heading,
    "button_group": []
  }

  const getSuggestedPosts = (limit) => {
    let allPostEdges = pageContext.allPostEdges;
    
    let postEdges = [];

    allPostEdges.some((postEdge, i) => {
      let node = postEdge.node;
      postEdges.push(postEdge)
      return i == (limit - 1);
    });

    return postEdges;
  }

  const articleGroupBlok = {
    title: "",
    description: "",
    button_title: "Read case study"
  }

  return (
    <Layout>

      <Seo title={pageContext.content.heading} />

      <article className={articleStyle}>
        <HeroHeader from="case-study" blok={story.content.heroHeader.length <= 0 ? heroHeaderblok : story.content.heroHeader} />

        <section {...sbEditable(story.content.content)} className={contentContainerStyle}>
          <aside>
            <p className={backButtonStyle}><Link to="../">{"Back"}</Link></p>
          </aside>
          <RichText content={story.content.content} from={"casestudy"} />
        </section>
        <section className={suggestedArticles}>
            <h2>More Case studies</h2>
            <aside><Link to={"/case-studies"}>View all case studies</Link></aside>
            <ArticleCardGroup attachedBlokData={getSuggestedPosts(3)} blok={articleGroupBlok} />
        </section>
      </article>


    </Layout>
  );

};

export default CaseStudyPost;